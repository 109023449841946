<template>
  <div id="metabolitebrowse">
    <MyheadCompnent></MyheadCompnent>
    <MetaboliteBrowseCompnent></MetaboliteBrowseCompnent>
    <MyfooterCompnent></MyfooterCompnent>
  </div>
</template>

<script>
import MetaboliteBrowseCompnent from '@/components/Browse/MetaboliteBrowseCompnent.vue'
import MyheadCompnent from '@/components/Common/MyheadCompnent.vue'
import MyfooterCompnent from '@/components/Common/MyfooterCompnent.vue'
export default {
  name: 'MetaboliteBrowse',
  data () {
    return {

    }
  },
  components: {
    MetaboliteBrowseCompnent,
    MyheadCompnent,
    MyfooterCompnent,
  },
}
</script>

<style scoped lang="scss">
</style>