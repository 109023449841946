<template>
  <div id="metabolitebrowsecompnent">
    <MetaboliteBrowseCompnentSearch :headT='head'></MetaboliteBrowseCompnentSearch>
    <el-container>
      <el-main>
        <el-collapse>
          <div>
            <el-collapse-item name="1">
              <template #title>
                <h2 style="border-bottom: none; top: 0px;margin: 15px 0px 15px 0px;text-align: right">Filter</h2>
              </template>
              <div class="inline-block">
                <h3>Formula</h3>
                <el-input v-model="formula" />
              </div>
              <div class="inline-block">
                <h3>Min molecular weight</h3>
                <el-input-number v-model="querydata.minWt" :min="1" :max="2000" />
              </div>
              <div class="inline-block">
                <h3>Max molecular weight</h3>
                <el-input-number v-model="querydata.maxWt" :min="1" :max="2000" />
              </div>
              <div class="inline-block mb-4">
                <el-button @click="filterButton" type="primary">Filter</el-button>
              </div>
            </el-collapse-item>
          </div>

        </el-collapse>
        <el-table :data="metaboliteData" style="width: 100%" @row-click="detailSearch">
          <el-table-column label="PMhub ID" width="180">
            <template #default="scope">
              <p>{{ scope.row.metabolite }}</p>
            </template>
          </el-table-column>
          <el-table-column prop="name" label="Name" width="280"></el-table-column>
          <!-- <el-table-column label="Structure1">
            <template #default="scope">
              <canvas :id="scope.row.metabolite" width="120" height="120"></canvas>
            </template>
          </el-table-column> -->
          <el-table-column label="Structure">
            <template #default="scope">
              <!-- <canvas :id="scope.row.metabolite" style="width: 150px;"></canvas> -->
              <div :id="scope.row.metabolite" style="width: 200px;height: 200px;"></div>
            </template>
          </el-table-column>
          <!-- <el-table-column label="Structure">
            <template #default="scope">
              <img :src="getSvg(scope.row.svg)" style="width: 150px;">
            </template>
          </el-table-column> -->
          <el-table-column prop="molecular" label="Formula"></el-table-column>
          <el-table-column prop="molWt" label="Average Mass"></el-table-column>
          <el-table-column prop="exactMolWt" label="Monoisotopic Mass"></el-table-column>
        </el-table>
      </el-main>
    </el-container>
    <div style="display: flex; justify-content: center">
      <el-pagination background layout="prev, pager, next" :total="count" :page-size="20" :current-page="querydata.page"
        @size-change="handleSizeChange" @current-change="handleCurrentChange">
      </el-pagination>
      <!-- <el-pagination background layout="prev, pager, next" :total="200" :page-size="20" :current-page="querydata.page"
        @size-change="handleSizeChange" @current-change="handleCurrentChange"></el-pagination> -->
    </div>
  </div>
</template>
<!--  prop="metabolite" -->
<script>
  import request from '@/network/request'
  import { ElLoading } from 'element-plus'
  import $ from 'jquery'
  // import ChemDoodle from "../../mymodules/ChemDoodleWeb-9.2.0/install/ChemDoodleWeb.js"
  import MetaboliteBrowseCompnentSearch from "@/components/Browse/MetaboliteBrowseCompnentSearch.vue"
  import common from '@/mymethods/common.js'
  import 'smiles-drawer'
  export default {
    name: 'MetaboliteBrowseCompnent',
    data () {
      return {
        querydata: {
          page: 1,
          size: 20,
          minWt: 1,
          maxWt: 2000,
        },
        formula: "",
        paginationArg: {

        },
        metaboliteData: "",
        head: "Requesting !",
        count: 0,
      }
    },
    methods: {
      test () {
        this.count += 1
      },
      initCanvas3 () {
        var data1
        for (data1 in this.metaboliteData) {
          let canvas3
          let context3
          canvas3 = new ChemDoodle.ViewerCanvas(this.metaboliteData[data1].metabolite, 120, 120)
          context3 = ChemDoodle.readMOL(this.metaboliteData[data1].cml)
          context3.scaleToAverageBondLength(14.4)
          canvas3.loadMolecule(context3)
        }
      },
      requestData () {
        const loading = ElLoading.service({
          lock: true,
          text: 'Loading',
          background: 'rgba(0, 0, 0, 0.7)',
        });
        this.formula = this.formula.toUpperCase()
        var querydataT = { ...this.querydata, ...{ "formula": this.formula } }

        request({
          // 路由不对
          url: 'metabolite2/list/',
          params: querydataT,
        }).then(res => {
          this.metaboliteData = res[0].MetaboliteId
          this.count = res[0].tableLen
          this.head = "Metabolites List"

          // this.fullscreenLoading.value = false
          // 渲染后回调函数,用画ChemDoodle结构图
          // this.$nextTick(() => {
          //   this.initCanvas3()
          // })
          loading.close()
        }).catch(err => {
          console.log(err);
        });
      },
      handleSizeChange (size) {
        this.pagesize = size
      },
      handleCurrentChange (currentPage) {
        this.querydata.page = currentPage
      },
      detailSearch (row, column, event) {

        this.$router.push({ name: 'MetaboliteDetail', params: { format: 'json', searchItem: row.metabolite } })
        // this.$router.push({ name: 'MetaboliteDetail', params: { format: 'json', searchItem: metabolite } })
      },
      getSvg (svgStr) {
        let blob = new Blob([svgStr], {
          type: 'image/svg+xml'
        });
        let blobStr = URL.createObjectURL(blob)
        return blobStr
        // let base64 = window.btoa(svgStr)
        // let template = `<img src="data:image/svg+xml;base64,${base64}">`
        // return template
      },
      getStructure (smiles, id, {
        width = 150,
        height = 150
      } = {}) {
        let options = {
          width: width,
          height: height
        }
        let smilesDrawer = new SmilesDrawer.Drawer(options);
        // smilesDrawer.draw('C1CCCCC1', 'draw2d')
        SmilesDrawer.parse(smiles, function (tree) {
          smilesDrawer.draw(tree, id, 'light', false);
        }, function (err) {
          console.log(err);
        })
      },
      filterButton () {
        if (this.querydata.page == 1) {
          this.requestData()
        } else {
          this.querydata.page = 1
        }

        // this.requestData()
      }
    },
    components: {
      MetaboliteBrowseCompnentSearch
    },
    created () {
      // this.requestData()

    },
    mounted () {
      // this.initCanvas()
      window.vue = this
    },
    updated () {

    },
    // 检测querydata的编号
    watch: {
      querydata: {
        handler (newName, oldName) {
          this.requestData()
        },
        immediate: true,
        deep: true,
      },
      metaboliteData: {
        handler (newName, oldName) {
          this.$nextTick(() => {
            for (let item in newName) {
              // this.getStructure(newName[item].smiles, newName[item].metabolite)
              common.drawMol(newName[item].mol, newName[item].metabolite)
            }
          })
        }
      }
    }
  }
</script>

<style scoped lang="scss">
  .el-main {
    background-color: #e9eef3;
    color: #333;
    text-align: center;
    /*    max-height: 1000px;
    overflow-y: scroll;*/
  }

  .el-select .el-input {
    width: 130px;
  }

  .input-with-select .el-input-group__prepend {
    background-color: #fff;
  }

  .el-table {
    cursor: pointer;
    font-size: 20px;
  }

  .inline-block {
    display: inline-block;
    margin-right: 30px;
  }
</style>